import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../views/shared.service'
import { navItems } from '../../_nav';
import { WindowService } from '../../views/role/window/window.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [WindowService]
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public navItemsNew: any []=[];
  changes: any;
  adUserId: any;
  aBranchId: any;
  aOrganizationId: any;
  winAccess: any[] = [];

  constructor(private windowService: WindowService, private Router: Router, @Inject(DOCUMENT) _document?: any){
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.adUserId = currentUser && currentUser.adUserId;
    this.aBranchId = currentUser && currentUser.aBranchId;
    this.aOrganizationId = currentUser && currentUser.aOrganizationId

  }

  ngOnInit(): void {
    if(this.adUserId != '1'){
      let userIdObj = {adUserId:''}
      userIdObj.adUserId = this.adUserId;
        this.windowService.getRoleWindowAccessUId(userIdObj).subscribe(data => {
          this.winAccess = data;

          this.winAccess.forEach(element => {
            let windowObj = {};
            windowObj = this.navItems.find(x => x.window == element.windowName);

            if(windowObj)
            this.navItemsNew.push(windowObj);
            
          });
         this.navItems = this.navItemsNew;
      });
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  authSignOut() {
    sessionStorage.removeItem('currentUser');
    this.Router.navigateByUrl('/login')
  }
}
