import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from '../app/views/auth.guard';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ActiveAacountComponent } from './views/active-aacount/active-aacount.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Page'
    }
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent,
    data: {
      title: 'reset Page'
    }
  },
  {
    path: 'active-account/:id',
    component: ActiveAacountComponent,
    data: {
      title: 'Active Account'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Dashboard'
    },
    children: [

      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'ats-user',
        loadChildren: () => import('./views/vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'role',
        loadChildren: () => import('./views/role/role.module').then(m => m.RoleModule),
        canActivate: [AuthGuard]
      }
      , {
        path: 'destination-master',
        loadChildren: () => import('./views/vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'shipping',
        loadChildren: () => import('./views/vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'document-sequence',
        loadChildren: () => import('./views/vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'client',
        loadChildren: () => import('./views/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'uom',
        loadChildren: () => import('./views/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'vendor',
        loadChildren: () => import('./views/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]

      },

      {
        path: 'consignment',
        loadChildren: () => import('./views/consignment/consignment.module').then(m => m.ConsignmentModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'invoice',
        loadChildren: () => import('./views/consignment/consignment.module').then(m => m.ConsignmentModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'manifest',
        loadChildren: () => import('./views/consignment/consignment.module').then(m => m.ConsignmentModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'driver-user',
        loadChildren: () => import('./views/organization/organization.module').then(m => m.OrganizationModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'product',
        loadChildren: () => import('./views/product/products-main.module').then(m => m.ProductsMainModule),
        canActivate: [AuthGuard]

      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
