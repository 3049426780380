/**
* authentication.service.ts - a service class for Authentication.

*/
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SharedService } from '../views/shared.service'

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4=',
  })
};

@Injectable()
export class AuthenticationService {
  public token: string;
  public schoolinfo : any={};
  public aBranchId: any;
  public adUserId: any;
  public username: any;
  public roleName: any;
  public aOrganizationId:any;
  public adRoleId:any;
  public msg:any;

  public classdivinfo: any=[];
  schoolid: any;
   class1: any;
   division: any;
   classteacher: any={};
   status: any;
  statusMessage: string = "Loading data...";
  loading = false;
  message:any={};
  address:any={}
  userType: any;


  constructor(private http: HttpClient, private SharedService:SharedService) {
    if(sessionStorage.getItem('currentUser') != ""){
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
      this.adUserId = currentUser && currentUser.adUserId;
      this.aBranchId = currentUser && currentUser.aBranchId;
    this.username = currentUser && currentUser.username;
    this.roleName = currentUser && currentUser.roleName;
      this.adRoleId = currentUser && currentUser.adRoleId;
      this.status = currentUser && currentUser.status;
      this.msg = currentUser && currentUser.msg;
      this.userType = currentUser && currentUser.userType;



  }

  }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  authenticate(userName: any, password: any): Observable<any> {
    sessionStorage.setItem("currentUser", "{}");
    // console.log(credentials+" api"+environment.api);

    return this.http.post<any>(environment.api + '/login', { userName: userName, password: password },httpOptions).pipe(
      map((Response) => {
                    // login successful if there's a jwt token in the response
        let token = Response && Response.message;
        let userType = Response && Response.userType;


        let msg = Response.message;


        // alert(msg)
        if (msg == "User Login successfully") {

        let adUserId = Response.adUserId;
        let aBranchId = Response.branchId;
        let username = Response.username;
        let aOrganizationId = Response.organizationId;
        let adRoleId = Response.adRoleId;
        let roleName = Response.roleName;
        let status = Response.status;
          let userType = Response.userType

        sessionStorage.setItem('currentUser', JSON.stringify({ aBranchId: aBranchId, status: status, username: username, roleName: roleName, adUserId: adUserId, aOrganizationId: aOrganizationId,
          msg: msg, adRoleId: adRoleId, userType:userType }));

                        // set token property


                        // store username and jwt token in session storage to keep user logged in between page refreshes

                        // this.toGetClassandDivid();
                        // console.log(sessionStorage.getItem('currentUser'));
                        // return true to indicate successful login
                        // return true;
          return userType;


        }


        else if (token == "User Login Failure"){
          alert("User Login Failure")
                        return token;
                    }



      }),
      catchError(this.handleError<any>('login'))
    );

  }

//   login(username: string, password: string) {
//     return this.http.post<any>(environment.api +'/userlogin', { username, password })
//         .pipe(map(user => {
//             // login successful if there's a user in the response
//             if (user) {
//                 // store user details and basic auth credentials in local storage
//                 // to keep user logged in between page refreshes

//                 user.authdata = window.btoa(username + ':' + password);
//                 localStorage.setItem('currentUser', JSON.stringify(user));
//             }

//             return user;
//         }));
// }

//   toGetClassandDivid(){

//     // let teacher=this.userId
//   this.SharedService.getByTeacher(this.userId,this.schoolid)
//   .subscribe((Data) => {

//     this.classdivinfo=Data
//     for (let i = 0; i < this.classdivinfo.length; i++) {
//       this.status = this.classdivinfo[i].is_class_teacher;
//       this.class1 = this.classdivinfo[i].classs.id;
//       this.division = this.classdivinfo[i].id;

//     }

//     // let class1 = this.classdivinfo.classs.id;
//     // let classid=this.class1.id;
//     // let division = this.classdivinfo.division.id;
//     // let divid=this.division.id;


//   // if(this.status=true){
//     sessionStorage.setItem('classdivisionDetails', JSON.stringify({class1: this.class1,division: this.division}));

//   // }
// },
// (error) => {
//   this.loading = false;
//   this.statusMessage = 'Problem with the service...Please try again after sometime',
//     alert(this.statusMessage);
// })
// }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };



  }
  logout() {
    this.msg = null;
    sessionStorage.removeItem('currentUser');
      }

}

