import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'ATS User',
    url: '/ats-user/ats-user-manager',
    // icon: 'fa fa-truck'
    window:'Ats user'
  },

  {
    name: 'Driver User',
    url: '/driver-user/driver-user',
    // icon: 'fa fa-files-o'
    window:'Driver User'
  },
  {
    name: 'Window',
    url: '/role/window',
    // icon: 'fa fa-truck'
    window:'Window'
  },
  {
    name: 'Role',
    url: '/role/role',
    // icon: 'fa fa-truck'
    window:'Role'
  },
  {
    name: 'Role Window Access',
    url: '/client/role-window',
    // icon: 'fa fa-truck'
    window:'Role Window Access'
  },
  {
    name: 'Document',
    url: '/document-sequence/document-sequence',
    // icon: 'fa fa-files-o'
    window:'Document'
  },
  {
    name: 'Shipping',
    url: '/shipping/shipping',
    // icon: 'fa fa-files-o'
    window: 'Shipping'
  },
  {
    name: 'Product Category',
    url: '/product/product-category',
    // icon: 'fa fa-user-circle-o'
    window:'Product Category'
  },
  {
    name: 'Product Sub Category',
    url: '/role/productSubCat',
    // icon: 'fa fa-truck'
    window:'Product Subcategory'
  },
  {
    name: 'Product Management',
    url: '/product/product-management-list',
    // icon: 'fa fa-users'
    window:'Product Management'
  },
  {
    name: 'Client Management ',
    url: '/client/client',
    // icon: 'fa fa-user-circle-o'
    window:'Client Management'
  },
  {
    name: 'Unit of Measurement',
    url: '/uom/uom-list',
    // icon: 'fa fa-user-circle-o'
    window:'Unit of Measurement'
  },
  {
    name: 'Price List',
    url: '/vendor/pricelist',
    // icon: 'fa fa-exchange'
    window:'Price List'
  },
  {
    name: 'Order',
    url: '/consignment/order',
    // icon: 'fa fa-shopping-cart'
    window:'Order'
  },
  {
    name: 'Schedule',
    url: '/ats-user/schedule',
    // icon: 'fa fa-shopping-cart'
    window:'Job Scheduling'
  },
  {
    name: 'Quote',
    url: '/consignment/quote-main',
    // icon: 'fa fa-shopping-cart'
    window:'Quote'
  },
  // {
  //   name: 'User Management',
  //   url: '/dashboard',
  // icon: 'fa fa-dashboard',
  // badge: {
  //   variant: 'info',
  //   text: 'NEW'
  // }
  // },
  // {
  //   title: true,
  //   name: 'Theme'
  // },


  // {
  //   name: 'Client User',
  //   url: '/destination-master/destination-master',
  //   // icon: 'fa fa-map-marker'
  // },




  // {
  //   name: 'Master Data',
  //   url: '/organization/organization',
  //   icon: 'fa fa-building-o'
  // },



  // {
  //   name: 'Quote',
  //   url: '/consignment/quote',
  //   icon: 'fa fa-shopping-cart'
  // },


  // {
  //   name: 'Order/Invoice ',
  //   url: '/invoice/invoice',
  //   // icon: "fa fa-file-text-o"
  // },
  // {
  //   name: 'Menu Name ',
  //   url: '/manifest/manifest',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Navbars',
  //       url: '/base/navbars',
  //       icon: 'icon-puzzle'

  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },


];
