import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from ".././shared.service";
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-active-aacount',
  templateUrl: './active-aacount.component.html',
  styleUrls: ['./active-aacount.component.css']
})
export class ActiveAacountComponent implements OnInit {

  uPath: any;
  id: any;
  adUserId: any;
  updateMessage: any;
  loading: boolean;
  statusMessage: string;
  add: any = {};
  cnfp: any = {};
  msg: any;
  @ViewChild("childModal", { read: false, static: false }) childModal: ModalDirective;

  constructor(private SharedService: SharedService, private location: Location, private route: ActivatedRoute,

    private router: Router,) {
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.adUserId = currentUser && currentUser.adUserId;

  }

  ngOnInit(): void {
    this.uPath = this.route.snapshot.url;
    this.id = this.uPath[1].path
  }
  activeLink() {





      this.loading = true;
      this.add.adUserId = this.id;



    this.SharedService.activeUser(this.add)
        .subscribe(data => {
          this.updateMessage = data;




          if (this.updateMessage.message == "Active User Failed") {
            this.childModal.show()
            this.loading = false;



          }

          if (this.updateMessage.message == "User Activated successfully") {
            this.msg = "User Activated successfully"
            this.childModal.show()
            this.loading = false;




          }
          else if (this.updateMessage.message == "Failed") {
            this.msg = "Failed"
            this.childModal.show()
            this.loading = false;

          }

        },
          error => {
            this.loading = false;
            this.statusMessage = 'Problem with the service...Please try later'
            alert(this.statusMessage);
          });



  }
  cancel() {
    this.childModal.hide()

  }
  cancel1() {
    if (this.msg == "User Activated successfully") {
      this.childModal.hide();
      this.router.navigateByUrl('/login');

    }

  }

}
