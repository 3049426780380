import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../environments/environment';
export interface IShared {


}


const httpOptions = {
  headers: new HttpHeaders({


    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='

  })
};
const httpOptionsMultipart = {
  headers: new HttpHeaders({
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};
@Injectable()
export class SharedService {

  constructor(private _http: HttpClient) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getClientsById(clientId: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listClientById', clientId, httpOptions).pipe(map((response) => {
      console.log("response ", response);
      return response
    }, catchError(this.handleError)));

  }

  saveVehicle(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/addUpdateVehicleMaster', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getAllVehicleMasterList(): Observable<IShared[]> {
    return this._http.get<IShared[]>(environment.api + '/listVehicleMaster', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getAllVehicleMasterListbyid(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listVehicleMasterById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteAllVehicleMasterListbyid(id: any): Observable<IShared[]> {
    return this._http.delete<IShared[]>(environment.api + '/deleteVehicleMaster/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  saveDestination(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/addUpdateDestination', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getAllDestinationList(): Observable<IShared[]> {
    return this._http.get<IShared[]>(environment.api + '/listDestination', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getAllDestinationMasterListbyid(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listDestinationById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  deleteAllDestionMasterbyid(id: any): Observable<IShared[]> {
    return this._http.delete<IShared[]>(environment.api + '/deleteDestination/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getAllmanifest(): Observable<IShared[]> {
    return this._http.get<IShared[]>(environment.api + '/listManifest', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteManifestbyid(id: any): Observable<IShared[]> {
    return this._http.delete<IShared[]>(environment.api + '/deleteManifest/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  addManifest(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/addUpdateManifest', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  getByIdM(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listManifestById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  adddcoument(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/addUpdateDocument', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getDs(): Observable<IShared[]> {
    return this._http.get<IShared[]>(environment.api + '/listDocument', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  getByIds(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listClientAddressById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getByIdsc(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listUserById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  deletedbyid(id: any): Observable<IShared[]> {
    return this._http.delete<IShared[]>(environment.api + '/deleteDocument/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  listRfqItemByIddat(id: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/listRfqItemById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  addimage(addImage: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/addUpdateRFQImage', addImage, httpOptionsMultipart).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  changePasswordUser(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/changepassword', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  forgot(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/forgotpasswordsendEmail', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  reset(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/changepasswordforgot', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  activeUser(add: any): Observable<IShared[]> {
    return this._http.post<IShared[]>(environment.api + '/activeuserlink', add, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
}
