import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from ".././shared.service";
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  uPath: any;
  id: any;
  adUserId: any;
  updateMessage: any;
  loading: boolean;
  statusMessage: string;
  add: any={};
  cnfp: any={};
  msg: any;
  @ViewChild("childModal", { read: false, static: false }) childModal: ModalDirective;

  constructor(private SharedService: SharedService, private location: Location, private route: ActivatedRoute,

    private router: Router,) {
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.adUserId = currentUser && currentUser.adUserId;

  }

  ngOnInit(): void {
    this.uPath = this.route.snapshot.url;
    this.id = this.uPath[1].path
  }
  resetPassword() {
    if (this.add.password != this.cnfp.cnf) {
      this.msg = "Password Not Match"
      this.childModal.show()


    }
    else {
this.loading=true;
      this.add.password = this.add.password;



      this.loading = true;
      this.add.adUserId = this.id;



      this.SharedService.reset(this.add)
        .subscribe(data => {
          this.updateMessage = data;




          if (this.updateMessage.message == "Password Not Match please try again later!") {
            this.childModal.show()
            this.loading = false;



          }

          if (this.updateMessage.message == "Change Password successfully") {
            this.msg ="Change Password successfully"
            this.childModal.show()
            this.loading = false;




          }
          else if (this.updateMessage.message == "Failed") {
            this.msg="Failed"
            this.childModal.show()
            this.loading = false;

          }

        },
          error => {
            this.loading = false;
            this.statusMessage = 'Problem with the service...Please try later'
            alert(this.statusMessage);
          });


    }
  }
  cancel(){
    this.childModal.hide()

  }
  cancel1() {
    if (this.msg =="Change Password successfully"){
     this.childModal.hide();
            this.router.navigateByUrl('/login');

   }

  }
}
