import { Component, OnInit, ViewChild } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '.././shared.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  adUserId: any;
  token: any;
  @ViewChild("childModal", { read: false, static: false }) childModal: ModalDirective;
  updateMessage: any;
  loading: boolean;
  statusMessage: string;
add:any={};
  msg: string;
  constructor(private SharedService: SharedService, private location: Location, private route: ActivatedRoute,

    private router: Router) {
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.adUserId = currentUser && currentUser.adUserId;
    this.token = currentUser && currentUser.token;
  }

  ngOnInit(): void {
  }
  sendMail() {
    this.loading = true;


    this.SharedService.forgot(this.add)
      .subscribe(data => {
        this.updateMessage = data;
        // this.loading = false;



        if (this.updateMessage.message == "Sent Mail successfully") {
          this.msg ="Sent Mail successfully"
          this.childModal.show()
          this.loading = false;



        }

        else if (this.updateMessage.message != "Sent Mail successfully") {
          this.msg="Failed"
          this.childModal.show()
          this.loading = false;


        }

      },
        error => {
          this.loading = false;
          this.statusMessage = 'Problem with the service...Please try later'
          alert(this.statusMessage);
        });




  }
  cancel(){
    this.childModal.hide()
  }

}
