import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  paymentType: any;
  roleId: any;

  constructor(private router: Router) { }

  /* canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  if (sessionStorage.getItem('currentUser')) {
   // logged in so return true


  let expectedPackageArray = route.data;
  expectedPackageArray = expectedPackageArray.packages;



  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  this.paymentType = currentUser && currentUser.payment;
  this.roleId = currentUser && currentUser.roleId;

  if(this.roleId == 4 && route.url.toString() =='patientView')
  return false;


  let  expectedPackage = '';

  for(let i=0; i<expectedPackageArray.length; i++){
    if(expectedPackageArray[i]==this.paymentType){
     // console.log("Roles Matched");
      expectedPackage = this.paymentType;
    }
  }

  if (this.paymentType == expectedPackage) {
    return true;
  }

  alert("Upgrade Package to Unlock Feature");
  return false;
          //return true;
  }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);

      return false;
  }*/


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    return false;
  }
}

