import { Injectable } from '@angular/core';
import { HttpModule, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({


    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='

  })
};
@Injectable({
  providedIn: 'root'
})


export class VendorService {

  constructor(private _http: HttpClient) { }

  getpricelist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listPricelistItem', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getProduct(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listProduct', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getplistbyId(id: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listPricelistItemById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  vendorDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deletePricelistItem/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  priceListMultiplerDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deletePricelistItemByProductPriceId/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  vendorUpdate(id: any, organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateVendor/' + id, organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  addpricelist(organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdatePricelistItem', organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }


  addUpdatepricelist(clId: any,sClId:any,discount:any,data:any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/clonepricelistitem/' + clId + "/" + sClId+"/"+discount,data,httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  addMultiple(data: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/updatemultiplepricelistitem' , data, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  additem(data: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateProductPrice', data, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getPriceItem(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listProductPrice', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }




  deletepList(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteProductPrice/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getVendorbanklist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listVendorBankAccount', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  getVendorBankListbyid(id: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listVendorBankAccountByvVendorId', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  bankDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteVendorBankAccount/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }



  vendorVehcileAdd(data: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateVendorVehicle', data, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getVendorVehicle(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listVendorVehicle', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }



  getVendorvehicleListbyid(id: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listVendorVehicleById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  deletevehicleV(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteVendorVehicle/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  vendorMatrixAdd(data: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateVendorContractMatrix', data, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getVMatrix(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listVendorContractMatrix', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  getVendorMtrixListbyid(id: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listVendorContractMatrixByVendorId', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }


  deleteMatrix(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteVendorContractMatrix/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
}
