import {Component, OnInit, Input} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../../views/authentication.service';
import { NgForm } from '@angular/forms';



@Component({
	selector:'login',
	templateUrl:'./login.component.html'
})
export class LoginComponent implements OnInit{

	public token: string;
    public userId:any;
    // @Input() model = { username:'', password: ''};
	loading=false;
	returnUrl:string;
	error = '';
	statusMessage:string;
    msg:any;
    model:any = {};
  data: any={};
  aBranchId: any;
  username: any;
  roleName: any;
  adRoleId: any;
  status: any;

	constructor(private route:ActivatedRoute,
				private router:Router,
				private authenticationService:AuthenticationService){
			var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        	this.token = currentUser && currentUser.token;
        	this.msg = currentUser && currentUser.msg;
			}

	ngOnInit(){

	}

    login() {
        this.loading = true;

      this.authenticationService.authenticate(this.model.userName, this.model.password)
        .subscribe(Response => {
          // let data = Response && Response.message;

// alert(data)
// console.log(Response)
          if (Response =="1") {
            // alert("User Login successfully")
                		this.router.navigate(['dashboard']);
            if (sessionStorage.getItem('currentUser') != "")
              this.toGetSchoolId();


                }
          if (Response == "2") {
            // alert("User Login successfully")
            this.router.navigate(['dashboard']);
            if (sessionStorage.getItem('currentUser') != "")
              this.toGetSchoolId();


          }
          else if (Response == "4") {
            sessionStorage.removeItem('currentUser');
            this.loading = false;
            alert("User Name or Password Invalid");
          }
          else if (Response == "3"){
            sessionStorage.removeItem('currentUser');
                        this.loading = false;
            alert("User Name or Password Invalid");
                }

                else {

                    this.error ='Problem with the service...Please try again after sometime';
                    this.loading = false;
                }
            },(error) => {
						this.loading = false;
						this.statusMessage="Problem with the service...Please try again after sometime";
						alert(this.statusMessage);
					   });
    }
  toGetSchoolId(){
    var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.userId = currentUser && currentUser.adUserId;
    this.aBranchId = currentUser && currentUser.aBranchId;
    this.username = currentUser && currentUser.username;
    this.roleName = currentUser && currentUser.roleName;
    this.adRoleId = currentUser && currentUser.adRoleId;
    this.status = currentUser && currentUser.status;
    this.msg = currentUser && currentUser.msg;

  }
}
