import { Injectable } from '@angular/core';
import { HttpModule, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({


    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='

  })
};
@Injectable({
  providedIn: 'root'
})
export class TripService {


  constructor(private _http: HttpClient) { }

  /* Apis for ATS */

  getUOMlist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listUnitofMeasurement', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }

  uomAddUpdate(uomDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateUnitofMeasurement', uomDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  uomDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteUnitofMeasurement/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  uomGetById(idObj: any): Observable<any[]> {
    return this._http.post<any[]>(environment.api + '/listUnitofMeasurementById' , idObj, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

/* Old Apis */
  getTriplist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listTrip', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getTripbyId(id: any): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listTripById' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  tripDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteTrip/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  tripUpdate(id: any, organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateTrip/' + id, organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  tripAdd(organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateTrip', organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getClientTripBill(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listClientTripBilling', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }

  getVendorTripBill(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listVendorTripBilling', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));
  }

  addUpdateClientTripBill(clientTripBill: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateClientTripBilling', clientTripBill, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  addUpdateVendorTripBill(vendorTripBill: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateVendorTripBilling', vendorTripBill, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  clientTripBillDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteClientTripBilling/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  vendorTripBillDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteVendorTripBilling/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getByClientTripBillId(clientTripBillId: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listClientTripBillingById', clientTripBillId, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  getByVendorTripBillId(vendorTripBillId: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/listVendorTripBillingById', vendorTripBillId, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }

  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }

}
