import { environment } from './../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='
  })
};
export interface IShared {


}
@Injectable()
export class WindowService {
  constructor(private httpClient: HttpClient) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }

  saveWindow(formData) {
    return this.httpClient.post(`${environment.api}/addUpdateWindow`, formData, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }
  getAllWindowListById(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listWindowById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getAllWindowList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listWindow', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getAllShippingList(): Observable<IShared[]> {
    return this.httpClient.get<IShared[]>(environment.api + '/listShippingMaster', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  saveShipping(formData) {
    return this.httpClient.post(`${environment.api}/addUpdateShippingMaster`, formData, httpOptions).pipe(map((response) => {
      return response
    }), catchError(this.handleError))
  }

  getAllShippingListById(id: any): Observable<IShared[]> {
    return this.httpClient.post<IShared[]>(environment.api + '/listShippingMasterById', id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteAllWindowListById(id: any) {

    return this.httpClient.delete(environment.api + '/deleteWindow/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  deleteAllShippingListById(id: any) {

    return this.httpClient.delete(environment.api + '/deleteShippingMaster/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getRoleWindowAccessUId(clientInfo: any): Observable<any[]> {

    return this.httpClient.post<any[]>(environment.api + '/listRoleWindowAccessByuserId', clientInfo, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
}
