import { Injectable } from '@angular/core';
import { HttpModule, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, retryWhen, delay, scan } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({


    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='

  })
};
@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private _http: HttpClient) { }
  getOrganizationlist(): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listOrganization', httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  getOrganizationbyId(id: any): Observable<any[]> {
    return this._http.get<any[]>(environment.api + '/listOrganizationById/' + id, httpOptions).pipe(map((response) => {
      return response
    }, catchError(this.handleError)));

  }
  organizationDelete(id: any): Observable<any[]> {
    return this._http.delete<any[]>(environment.api + '/deleteOrganization/' + id, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  organizationUpdate(id: any, organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateOrganization/' + id, organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  organizationAdd(organizationDto: any): Observable<any[]> {

    return this._http.post<any[]>(environment.api + '/addUpdateOrganization', organizationDto, httpOptions).pipe(map((response) => {
      return response
    },
      catchError(this.handleError)));
  }
  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }
}
